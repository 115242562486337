import { AButton } from '@allvit/allvit-toolkit';
import { Stack, StackProps, SxProps, Theme, useTheme } from '@mui/material';

import { Link } from '@remix-run/react';
import { useState } from 'react';
import { Check as IconCheck } from 'react-feather';

import { BookTopicTypes } from '~/types/app/book';
import { mergeSx } from '~/utils/mui';

declare module '@mui/material/Chip' {
    interface ChipPropsSizeOverrides {
        large: true;
    }
}
interface TopicsProps extends StackProps {
    topics: Array<BookTopicTypes>;
    showEmojis?: boolean;
    center?: boolean;
    chipSx?: SxProps<Theme>;
    onTopicClick?: (topic: BookTopicTypes) => void;
    size?: 'small' | 'medium' | 'large';
    columns?: number;
    excludeTopics?: Array<string>;
    selectedTopics?: Array<string>;
}

const Topics = ({
    selectedTopics,
    center,
    topics,
    showEmojis,
    onTopicClick,
    columns,
    size,
    sx,
    chipSx,
    excludeTopics,
    ...props
}: TopicsProps) => {
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const showGrid = columns && columns > 0;
    const emojiFontSize = size === 'large' ? 20 : 18;

    let gridGap = { xs: theme.spacing(3), md: theme.spacing(4) };

    if (size === 'large') {
        gridGap = { xs: theme.spacing(4), md: theme.spacing(6) };
    }

    let gridTemplateColumns: string | { xs: string; md?: string; lg?: string } = {
        xs: '1fr',
        md: '1fr 1fr',
        lg: '1fr 1fr 1fr',
    };

    if (columns && columns > 2) {
        gridTemplateColumns = { xs: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' };
    } else if (columns && columns > 1) {
        gridTemplateColumns = { xs: '1fr', md: '1fr 1fr' };
    } else if (columns && columns > 0) {
        gridTemplateColumns = '1fr';
    }

    let localButtonSx = { textAlign: 'left' } as SxProps<Theme>;

    if (size === 'large' && !showGrid) {
        localButtonSx = { textAlign: 'left', mr: { xs: 4, md: 6 }, mb: { xs: 4, md: 6 } };
    } else if (!showGrid) {
        localButtonSx = { textAlign: 'left', mr: { xs: 3, md: 4 }, mb: { xs: 3, md: 4 } };
    }

    const toggleShow = () => {
        setShow(!show);
    };

    const buttons = topics
        .filter((topic) => !excludeTopics || (excludeTopics && !excludeTopics.includes(topic.label)))
        .map((topic) => {
            const navigationProps = onTopicClick
                ? {
                      onClick: () => {
                          onTopicClick(topic);
                      },
                  }
                : {
                      component: Link,
                      to: `/fagomrade/${topic.routeId}`,
                  };

            return (
                <AButton
                    key={topic.key}
                    wrapContent
                    size={size}
                    variant="quaternary"
                    selected={selectedTopics && selectedTopics.includes(topic.label)}
                    {...navigationProps}
                    sx={mergeSx(localButtonSx, chipSx)}
                >
                    {showEmojis && (
                        <Stack
                            component="span"
                            sx={{
                                mr: 3,
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                fontSize: emojiFontSize,
                            }}
                        >
                            {selectedTopics && selectedTopics.includes(topic.label) ? (
                                <Stack
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: theme.palette.cta.main,
                                        borderRadius: '50%',
                                    }}
                                >
                                    <IconCheck color={theme.palette.common.white} size={13} strokeWidth={3} />
                                </Stack>
                            ) : (
                                topic.emoji
                            )}
                        </Stack>
                    )}
                    {topic.label}
                </AButton>
            );
        });

    if (showGrid) {
        return (
            <Stack
                direction="row"
                sx={mergeSx(
                    {
                        display: 'grid',
                        alignItems: 'start',
                        gridTemplateColumns,
                        gridGap,
                    },
                    sx,
                )}
                {...props}
            >
                {buttons}
            </Stack>
        );
    }
    return (
        <>
            <Stack
                direction="row"
                sx={mergeSx(
                    {
                        transition: 'max-height 0.3s',
                        maxHeight: show ? '3000px' : { xs: '204px', md: '3000px' },
                        overflow: 'hidden',
                        flexWrap: 'wrap',
                        justifyContent: center ? 'center' : 'flex-start',
                    },
                    sx,
                )}
                {...props}
            >
                {buttons}
            </Stack>
            <Stack sx={{ display: { xs: 'flex', md: 'none' }, pt: 6, alignItems: 'center' }}>
                <AButton onClick={toggleShow} variant="secondary" size="large">
                    {show ? 'Vis færre fagområder' : 'Vis flere fagområder'}
                </AButton>
            </Stack>
        </>
    );
};

export default Topics;
